import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'assetMatching',
  components: {
    appStrings,
    DatePicker
  },
  props: ['currentLoc', 'locId', 'erpLoctnOrgId','locAccessFlag'],
  watch: {
    currentPage: function() {
      this.getRealappAssets();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRealappAssets();
    },
    currentPage1: function() {
      this.getOracleAssets();
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.getOracleAssets();
    },
    currentPage2: function() {
      this.getMatchedAssets()
    },
    perPage2: function() {
      this.currentPage2 = 1;
      this.getMatchedAssets()
    }
  },
  data() {
    return {
      totalRows: null,
      totalRows1: null,
      totalRows2: null,
      currentPage: 1,
      currentPage1: 1,
      currentPage2: 1,
      loader: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      perPage1: commonHelper.perPageRecord,
      perPage2: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      showValueSetModal: false,
      responseMsg: '',
      isSuccess: false,
      editMode: false,
      poNum: null,
      orcAssetNum: null,
      orcAssetId: null,
      orcSerialNum: null,
      realAssetNum: null,
      realSerialNum: null,
      assetOracleMatchingData: [],
      assetOracleMatchingFields: [
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          class: 'selectRadio sel',
          variant: 'primary'
        },
        {
          key: 'asset_id',
          class: 'col-fix'
        },
        {
          key: 'asset_number',
          class: 'col-fix'
        },
        // {
        //     key:'asset_type',
        //     class:'col-fix'
        // },
        {
          key: 'serial_number',
          class: 'col-fix'
        },
        {
          key: 'description',
          label: 'Item Description',
          class: 'col-fix'
        },
        {
          key: 'po_number',
          label: 'PO Number'
        },
        {
          key: 'line_num',
          label: 'PO Line Number'
        }
      ],
      assetRealAppMatchingData: [],
      assetRealAppMatchingFields: [
        {
          key: 'selectRadio1',
          label: 'Select',
          stickyColumn: true,
          class: 'selectRadio1',
          variant: 'primary'
        },

        {
          key: 'doc_num',
          label: 'Asset Number',
          class: 'col-fix'
        },
        {
          key: 'fa_item_type',
          label: 'Asset Type',
          class: 'col-fix'
        },
        {
          key: 'serial_no',
          class: 'col-fix'
        },
        {
          key: 'item_name',
          label: 'Item Description'
        },
          {
             key:'asset_id',
             class:'d-none'
          }
      ],
      assetOracleRealappMatchedData: [],
      assetOracleRealappMatchedFields: [
        {
          key: 'oracle_id',
          label: 'Oracle Asset Id',
          class: 'col-fix'
        },
        //   {
        //     key:'asset_id',
        //     label:'Realapp Asset Id',
        //     class:'col-fix'
        // },
        {
          key: 'doc_num',
          label: 'Realapp Asset Number',
          class: 'col-fix'
        },
        {
          key: 'serial_num',
          label: 'Serial Number',
          class: 'col-fix'
        },
        {
          key: 'fa_item_type',
          label: 'Asset Type',
          class: 'col-fix'
        },
        {
          key: 'description',
          label: 'Item Description',
          class: 'col-fix'
        }
      ],
      orcMatchedAssetId: null,
      realMatchedAssetNum: null,
      oracleAssetId:null,
      realAssetId:null,
    };
  },
  mounted() {
    this.getRealappAssets();
    this.getOracleAssets();
    this.getMatchedAssets();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          //   this.showAssetLiqModal(true)
        } else if (actionName === 'save' && !this.showAssetLiqModalFlag) {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getOracleAssets() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        hr_loc_id: this.erpLoctnOrgId,
        po_number: this.poNum,
        asset_id: this.orcAssetId,
        serial_number: this.orcSerialNum
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getOracleAssetsDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetOracleMatchingData = resp.data.data.data.page;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    getRealappAssets() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        hrLocId: this.locId,
        doc_num: this.realAssetNum,
        serial_num: this.realSerialNum
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getRealappAssetsDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetRealAppMatchingData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getMatchedAssets() {
      const payload = {
        hrLocId: this.locId,
        queryParams: {
          _page: this.currentPage2 - 1,
          _limit: this.perPage2,
          doc_num: this.realMatchedAssetNum,
          oracle_id: this.orcMatchedAssetId
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getMatchedOracleRealappAssets', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetOracleRealappMatchedData = resp.data.data.page;
            this.totalRows2 = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    manualMatching(){
      this.assetOracleMatchingData.map(ele=>{
        if(ele.selectRadio){
          this.oracleAssetId=ele.asset_id
        }
      })
      this.assetRealAppMatchingData.map(ele=>{
        if(ele.selectRadio1){
          this.realAssetId=ele.asset_id
        }
      })
      if(!this.oracleAssetId || !this.realAssetId){
        this.$bvToast.toast("Select Oracle and Realapp detail", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }else{
        const payload = {
          oracle_asset_id: this.oracleAssetId,
          asset_id: this.realAssetId,
      };
      this.loader = true;
      this.$store
        .dispatch('assets/postManualAssetsMatching', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.oracleAssetId=null,
            this.realAssetId=null,
            this.getRealappAssets();
            this.getOracleAssets();
            this.getMatchedAssets();
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    clearValues(item) {
      if (item == 'oracle') {
        this.poNum = null;
        this.orcAssetId = null;
        this.orcSerialNum = null;
      } else if (item == 'realapp') {
        this.realAssetNum = null;
        this.realSerialNum = null;
      } else if (item == 'matched') {
        this.orcMatchedAssetId = null;
        this.realMatchedAssetNum = null;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
