import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'Employee',
  components: {
    ModelSelect,
    DatePicker
  },
  props: { showEmployeeModal: Boolean },
  watch: {
    currentPage: function() {
      this.getEmployeeLis();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getEmployeeLis();
    }
  },
  data() {
    return {
      unsubscribe: null,
      actionName: null,
      showEmployeeDetails: false,
      loader: false,
      showAlert: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      setTime: null,
      stickyHeader: true,
      totalRows: null,
      selectFilter: false,
      searchForm: {
        emp_status: null,
        // joining_from: null,
        employee_num: null,
        employee_email:null,
        // start_date_end_date: null,
        // legalEntity: {
        //   value: null,
        //   text: null
        // },
        // hrms_org_key_flexfield_comb_id: null,
        // hrms_org_key_flexfield_comb_text: null,
        // joining_to: null,
        // le_id: null,
        employee_name: null,
        // designation_vset: null,
        // supervisor_num: null
      },
      selectedEmpStatus: {
        value: null,
        text: null
      },
      showValueSetModal: false,
      vsetCode: null,
      empStatusList: [
        {
        value: 'Inactive',
        text: 'Inactive'
      }],
      employeesList: [],
      employeeFields: [
        {
          key: 'employee_num',
          label: 'Emp. No.'
        },
        {
          key:'employee_detail',
          label: 'Emp. Name'
        },
        // {
        //   key: 'flex_value',
        //   class: 'd-none'
        // },
        // {
        //   key: 'le_name',
        //   label: 'Legal Entity'
        // },
        {
          key:'official_email',
          label: 'Emp. Email'
        },
        // {
        //   key: 'active',
        //   label: 'Status'
        // }
      ],
      empDetails:{
        employeeNum:null,
        employeeMail:null
      }
     
    };
  },
  mounted() {
    this.getLookupData(appStrings.LOOKUPTYPE.EMPLOYMENT_STATUS);
    // this.getEmployeeLis();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
              actionName === 'add'
            ) {
                // this.addeditdata()
            }
      }
    });
  },
  methods: {
    getEmployeeLis() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        emp_num: this.searchForm.employee_num,
        email: this.searchForm.employee_email,
        empdetail:this.searchForm.employee_name,
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getEmpList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.employeesList = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    applySearch() {
      this.currentPage = 1;
      this.getEmployeeLis();
    },
    clearSearch() {
      this.searchForm = {
        emp_status: null,
        employee_num: null,
        employee_name: null,
        employee_email:null
      };
      this.selectedEmpStatus = {
        value: null,
        text: null
      };
      this.employeesList = [];
      this.currentPage= 1;
      this.totalRows= null;
      // this.getEmployeeLis();
    },
    rowSelected(item) {
      this.$emit('closeModal', false,this.empDetails={
        employeeNum:item.employee_num,
        employeeMail:item.official_email
      });
    },

    getLookupData(lookupCode) {
      this.$store
        .dispatch('shared/getDataFromLookupsByLookupType', lookupCode)
        .then(response => {
          if (response.data.data) {
            const result = response.data.data;
            const salutationArr = result.map(type => {
              return {
                value: type.lookup_code,
                text: type.meaning
              };
            });
            this.empStatusList = salutationArr;
          }
        })
        .catch(error => {
          alert(error.message);
        });
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
