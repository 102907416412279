import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import assetViewDetails from '../../assetAudit/assetViewDetails';
import setTimeout from 'core-js/library/fn/set-timeout';

export default {
  name: 'addEditAssetLiquidation',
  components: {
    appStrings,
    DatePicker,
    assetViewDetails
  },
  props: ['currentLoc','locId','rowData','switchFlag','assetLiquidationDetails','eventId','updateStatus','locAccessFlag'],
  watch: {
    currentPage: function() {
      this.getAssetLiqDetails();
    },
    updateStatus: function() {
        this.getSavedAssetLiqDetails();
        this.eveFlag=true
        this.assetLiquidationSavedFields.splice(0,2)
        this.getMenuId()
      },
    perPage: function() {
      this.currentPage = 1;
      this.getAssetLiqDetails();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      showValueSetModal: false,
      responseMsg: '',
      isSuccess: false,
      batchNumber: null,
      approvalStatus: null,
      hrLocation: {
        text: null,
        value: null
      },
      assetLiquidationData: [],
      assetLiquidationFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1 wid'
        },
        {
          key: 'doc_num',
          label: 'Asset Number'
        },
        {
          key: 'asset_type'
        },
        {
          key: 'serial_no',
          label: 'Serial Number'
        },
        {
            key: 'asset_details'
          }
      ],
      assetLiquidationSavedData: [
        // {
        //   doc_num:null,
        //   asset_type:null,
        //   serial_number:null,
        //   reason_for_liquidation:null
        // }
      ],
      assetLiquidationSavedFields: [
        // {
        // key: 'add',
        // stickyColumn: true,
        // },
        // {
        //   key: 'selectBox1',
        //   label: 'Select',
        //   stickyColumn: true,
        //   variant: 'info',
        //   class:'stickyColumn col-sm-1 wid'
        // },
        {
          key: 'remove',
          stickyColumn: true,
        },
        {
          key: 'doc_num',
          label: 'Asset Number'
        },
        {
          key: 'asset_type'
        },
        {
          key: 'serial_no',
          label:"Serial Number"
        },
        {
          key: 'remarks',
          label:'Reason For Liquidation'
        },
        {
          key:'batch_dtl_id',
          class:'d-none'
        },
        {
          key: 'asset_details'
        }
      ],
      approvalHistoryData: [],
      approvalHistoryFields: [
        {
          key: 'approver_name'
        },
        { key: 'approver_designation', label: 'Designation' },
        {
          key: 'action_date'
        },
        {
          key: 'seq_order',
          label: 'Approver Order'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments'
        }
      ],
      selectedRow: [],
      selectedRow1: [],
      selectAllCheckBox: false,
      selectAllCheckBox1: false,
      checkFlag: false,
      checkFlag1: false,
      reasonLiq: null,
      showAssetDetailsModalFlag:false,
      assetNum:null,
      batchId:null,
      allFlag: false,
      wfMenuId: null,
      eventID: null,
      assetNumber:null,
      assetType:{
        text:null,
        value:null
      },
      serialNumber:null,
      editMode:false,
      accessButtons: {},
      remCount:0,
      eveFlag:false,
      wfDtlId:null,
      showApproveModal:false,
      showRejectModal:false,
      approvalReason:null,
      rejectedReason:null,
      hrLoc:null
    };
  },
  mounted() {
    this.hrLoc=this.currentLoc?this.currentLoc.text:this.assetLiquidationDetails.location_name
    if(this.rowData){
        this.batchId=this.rowData.batch_id
        this.approvalStatus=this.rowData.approval_status
        this.getSavedAssetLiqDetails();
    } else if(this.assetLiquidationDetails){
        this.approvalStatus=this.assetLiquidationDetails.approval_status
        this.batchId=this.assetLiquidationDetails.liq_hdr_id;
        this.wfDtlId=this.assetLiquidationDetails.wf_inprocess_dtl_id;
        this.getSavedAssetLiqDetails();
    }
    if(this.eventId){
        this.getHistory(this.eventId)
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && !this.showAssetDetailsModalFlag) {
          this.postAssetLiqDetails()
        }else if((actionName === 'update' || actionName === 'edit') && !this.showAssetDetailsModalFlag){
            this.editMode = true;
        }
      }
    });
    if(this.approvalStatus=="APPROVED" || this.approvalStatus=="REJECTED" || this.approvalStatus=="INPROCESS"){
        this.eveFlag=true
        this.assetLiquidationSavedFields.splice(0,2)
        this.getMenuId()
    }else{
      this.getAssetLiqDetails();
    }
  },
  methods: {
    approveRejectInvoiceApproval(status) {
        const payload = {
          event_id: this.eventId,
          wf_inprocess_dtl_id: this.wfDtlId,
          notification_status: status,
          notification_comments:
            status === 'REJECTED' ? this.rejectedReason : this.approvalReason
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/approvalRejectSingle', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.getSavedAssetLiqDetails();
              this.getAssetLiqDetails();
              this.getMenuId()
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              if (status === 'REJECTED') {
                this.showHideRejectModal(false);
              } else if (status === 'APPROVED') {
                this.showHideApproveModal(false);
              }
              this.$emit('updateAssetLiq');
            } else {
              this.showAlert = false;
              alert(response.response.data.message);
              // this.isSuccess = false;
              // this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      },
    showHideApproveModal(flag) {
          this.showApproveModal = flag;
        if (!flag) {
          this.approvalReason = null;
        }
      },
      showHideRejectModal(flag) {
        this.showRejectModal = flag;
        if (!flag) {
          this.rejectedReason = null;
        }
      },
    removeRow(index,data) {
      if(this.editMode){
        this.assetLiquidationSavedData.splice(index, 1);
        const payload = {
          doc_num:data.doc_num
        };
        this.loader = true;
        this.$store
          .dispatch('assets/deleteSavedLiqAsset', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.showAlert = true;
              this.isSuccess = true; 
              this.responseMsg = resp.data.message;
              this.getAssetLiqDetails()
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
      else{
        this.$bvToast.toast("Kindly turn on edit mode", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }

    },

    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.checkFlag = flag;
        this.assetLiquidationData = this.assetLiquidationData.map(data => {
          data.selectBox = this.selectAllCheckBox;
          return data;
        });
        this.selectedRow = this.assetLiquidationData;
      } else {
        this.checkFlag = flag;
        this.assetLiquidationData = this.assetLiquidationData.map(data => {
          data.selectBox = this.selectAllCheckBox;
          return data;
        });
        this.selectedRow = [];
      }
    },
    selectBoxChecked(flag, index, item, assetNo) {
      this.assetLiquidationData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.doc_num !== assetNo
        );
      }
      if (this.selectedRow.length > 0 || this.selectAllCheckBox) {
        this.checkFlag = true;
      } else {
        this.checkFlag = false;
      }
    },
    addData() {
      this.selectedRow.map(ele=>{
        this.assetLiquidationSavedData.push(ele)
      })
    this.checkFlag = false;
    this.getAssetLiqDetails()
    this.selectedRow=[]
    },
    selectAllBoxChecked1(flag) {
      this.selectedRow1 = [];
      if (flag) {
        this.checkFlag1 = flag;
        this.assetLiquidationSavedData = this.assetLiquidationSavedData.map(
          data => {
            data.selectBox1 = this.selectAllCheckBox1;
            return data;
          }
        );
        this.selectedRow1 = this.assetLiquidationSavedData;
      } else {
        this.checkFlag1 = flag;
        this.assetLiquidationSavedData = this.assetLiquidationSavedData.map(
          data => {
            data.selectBox1 = this.selectAllCheckBox1;
            return data;
          }
        );
        this.selectedRow1 = [];
      }
    },
    selectBoxChecked1(flag, index, item, assetNo) {
      this.assetLiquidationSavedData[index].selectBox1 = flag;
      if (flag === true) {
        this.selectedRow1.push(item);
      } else {
        this.selectedRow1 = this.selectedRow1.filter(
          el => el.doc_num !== assetNo
        );
      }
      if (this.selectedRow1.length > 0 || this.selectAllCheckBox1) {
        this.checkFlag1 = true;
      } else {
        this.checkFlag1 = false;
      }
    },
    clearValues() {
      this.reasonLiq = null;
    },
    getAssetLiqDetails() {
      const payload = {
        params: {
          hrLocId: this.locId
        },
        queryParams: {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          doc_num:this.assetNumber,
          item_id:this.assetType.value,
          serial_no:this.serialNumber
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetLiqDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetLiquidationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
            if(this.selectedRow.length){
              this.assetLiquidationData.map(ele=>{
                this.selectedRow.map(el=>{
                  if(ele.doc_num==el.doc_num){
                    ele.selectBox=true
                  }
                })
              })
            }
            this.assetLiquidationData=this.assetLiquidationData.filter(item => !this.assetLiquidationSavedData.some(obj => obj.doc_num === item.doc_num))
            this.selectAllCheckBox = false;
        }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addReason() {
      this.assetLiquidationSavedData.map(ele => {
          ele.remarks = this.reasonLiq;
      });
      setTimeout(() => {
        this.assetLiquidationSavedData = JSON.parse(
          JSON.stringify(this.assetLiquidationSavedData)
        );
      }, 0);
    },
    postAssetLiqDetails() {
      if(this.assetLiquidationSavedData.length==0){
        this.$bvToast.toast("Kindly add assets to saved assets", {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
      else{
      const payload = {
        liq_hdr_id: this.batchId?this.batchId:0,
        location_id: this.locId,
        liq_dtl: []
      };
      this.remCount=0
      this.assetLiquidationSavedData.map(ele => {
        if (ele.remarks) {
          payload.liq_dtl.push({
            asset_type: ele.asset_type,
            doc_num: ele.doc_num,
            liq_dtl_id: ele.batch_dtl_id? ele.batch_dtl_id:0,
            remarks: ele.remarks,
            serial_number: ele.serial_no,
          });
        }
        else if(ele.remarks==null || ele.remarks==""){
            this.remCount++
        }
      });
     if(this.remCount>0){
        this.$bvToast.toast("Reason for liquidation field can not be empty", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
      }
      else{
        this.loader = true;
        this.$store
          .dispatch('assets/postAssetLiqDetails', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = resp.data.message;
              this.editMode = false;
              this.totalRows = resp.data.data.total_elements;
              this.selectAllCheckBox1 = false;
              this.selectBox1 = false;
              this.selectedRow=[]
              this.selectedRow1=[]
              this.batchId=resp.data.data.batch_id;
              this.getAssetLiqDetails();
              this.getSavedAssetLiqDetails();
              this.$emit('getBatchAssetLiqDetails');
              this.$emit('rowSelected',resp.data.data)

            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    }   
    },
    getSavedAssetLiqDetails() {
      const payload = {
        params: {
            batchId: this.batchId
        },
        queryParams: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getSavedAssetLiqDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.assetLiquidationSavedData = resp.data.data.liq_dtl;
            this.totalRows = resp.data.data.total_elements;
            this.batchNumber=resp.data.data.batch_num;
            this.approvalStatus=resp.data.data.approval_status;
            this.batchId=resp.data.data.batch_id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },  
    showAssetDetailsModal(flag,asstDetail){
      this.assetNum=asstDetail;
      this.showAssetDetailsModalFlag=flag;
      if (flag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
    }
  },
  getMenuId() {
    const payload = {
      event_name: this.$route.name
    };
    this.$store
      .dispatch('intWorkflow/getDynamicMenuId', payload)
      .then(response => {
        if (response.status === 200) {
          const result = response.data.data;
          if (result) {
            this.wfMenuId = result.menu_id;
            this.getEventId();
          }
        }
      });
  },
  getEventId() {
    this.$store
      .dispatch('intWorkflow/getEventId', this.wfMenuId)
      .then(response => {
        if (response.status === 200) {
          this.eventID = response.data.data;
          this.getHistory()
        }
      });
  },
  switchVariable() {
    this.allFlag = this.allFlag
      ? (this.allFlag = false)
      : this.allFlag == false
      ? (this.allFlag = true)
      : null;
    this.getHistory();
  },
  getHistory(id) {
    const payload = {
      eventId: this.eventID?this.eventID:id,
      nfaHdrId: this.batchId,
      approvalStatus: this.allFlag ? 'Y' : !this.allFlag ? 'N' : ''
    };
    this.loader = true;
    this.$store
      .dispatch('intWorkflow/getApprovalList', payload)
      .then(response => {
        this.loader = false;
        if (response.status === 200) {
          this.approvalHistoryData = response.data.data;
        }
      })
      .catch(() => {
        this.loader = false;
      });
  },
  clearUser(){
    this.assetNumber=null;
    this.serialNumber=null;
    this.assetType=null
  },
  openValueSetModal(vsetCode) {
    this.parent_value_set_id = null;
    this.vsetCode = vsetCode;
    this.showValueSetModal = true;
    if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
      this.parent_value_set_id = 'ASSET';
    }
  
    this.setTimeVsetCode = setTimeout(() => {
      const vSetData = {
        valueSetName: vsetCode,
        multiFlag: null
      };
      this.eventBus.$emit('valueSetCode', vSetData);
    }, 0);
  },
  selectedvalueSet(item) {
    if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
      this.assetType = {
        value: item.value_set_dtl_id,
        text: item.item_name        
      };
    } 
  },
  closeValueSetModal() {
    this.showValueSetModal = false;
  },
  clearVsetValues(vsetCode) {
    if (vsetCode === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
        this.assetType = {
          value: null,
          text: null
        };
      } 
  },
  removeRowLiq(){
    const payload = {
      liq_hdr_id:this.batchId
    };
    this.loader = true;
    this.$store
      .dispatch('assets/scrapLiquidationBatch', payload)
      .then(resp => {
        this.loader = false;
        if (resp.status === 200) {
          this.showAlert = true;
          this.isSuccess = true; 
          this.responseMsg = resp.data.message;
          this.getSavedAssetLiqDetails();
            this.$emit('showAssetLiqModal',false,this.approvalStatus);
        } else {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = resp.response.data.message;
        }
      })
      .catch(() => {
        this.loader = false;
      }); 
}
  },
  beforeDestroy() {
    this.unsubscribe();
    this.eventBus.$off('getSavedData');
  }
};
